import {
  RechargeFun,
  GetRechargeListFun,
} from './../../services/API/global/interface';

export class GetLastSpentToken {
  static readonly type = 'get last spend token';

  constructor(public lastSpentToken?: number) {}
}

export class RechargeToken {
  static readonly type = 'recharge token';

  constructor(public params: FunType.FirstParameters<RechargeFun>) {}
}

export class GetRechargeList {
  static readonly type = 'get recharge list';

  constructor(public params: FunType.FirstParameters<GetRechargeListFun>) {}
}
