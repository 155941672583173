export const API = {
  user: {
    register: '/api/v1/register',
    login: '/api/v1/login',
    sendSMSForRegister: '/api/v1/register/sendSMS',
    sendSMSForModifyPassword: '/api/v1/login/sendSMS',
    modifyPassword: '/api/v1/login/modifyPassword',
    infos: '/api/v1/user/infos',
    setInfos: '/api/v1/user/setInfos',
  },
  token: {
    lastSpent: '/api/v1/token/lastSpent',
    recharge: '/api/v1/token/recharge',
    rechargeList: '/api/v1/token/rechargeList',
    infos: '/api/v1/token/infos',
  },
  chat: {
    chatModelList: '/api/v1/chat/chatModelList ',
    sendMessage: '/api/v1/chat/stream/sendMessage',
    star: '/api/v1/chat/star',
    starList: '/api/v1/chat/starList',
    chatModelInfo: '/api/v1/chat/chatModelInfo',
    feedback: '/api/v1/chat/feedback',
  },
  enterprise: {
    infos: '/api/v1/enterprise/infos',
  },
};
