export const portraits: Array<Portrait.Portrait> = [
  {
    src: './assets/portrait/1.png',
    id: 1,
  },
  {
    src: './assets/portrait/2.png',
    id: 2,
  },
  {
    src: './assets/portrait/3.png',
    id: 3,
  },
  {
    src: './assets/portrait/4.png',
    id: 4,
  },
  {
    src: './assets/portrait/5.png',
    id: 5,
  },
  {
    src: './assets/portrait/6.png',
    id: 6,
  }
];
