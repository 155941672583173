import { Injectable } from '@angular/core';

import { IndexedDBService } from '../../../indexedDB/indexedDB.service';
import {
  GetChatRecordListFun,
  AddQuestionFun,
  AddAnswerFun,
  DeleteChatRecordByChatCategoryIdFun,
  DeleteChatRecordByIdFun,
  UpdateAnswerFun,
  UpdateQuestionFun,
} from './interface';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBChatService {
  constructor(private IndexedDB: IndexedDBService) {}

  addQuestion: AddQuestionFun = async (params) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.addData<Chat.Quesition>(
        db,
        'chat',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  addAnswer: AddAnswerFun = async (params) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.addData<Chat.Answer>(
        db,
        'chat',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateAnswer: UpdateAnswerFun = async (
    params: Chat.Answer
  ): Promise<Chat.Answer> => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.updateDB<Chat.Answer>(
        db,
        'chat',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateQuestion: UpdateQuestionFun = async (
    params: Chat.Quesition
  ): Promise<Chat.Quesition> => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.updateDB<Chat.Quesition>(
        db,
        'chat',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getChatRecordList: GetChatRecordListFun = async (chatCategoryId) => {
    try {
      const db = await this.IndexedDB.openDB();

      const data = await this.IndexedDB.cursorGetDataByIndex<
        typeof chatCategoryId,
        Chat.ChatRecord
      >(db, 'chat', 'chatCategoryId', chatCategoryId);

      return data;
    } catch (error) {
      console.log(error)
      return Promise.reject(error);
    }
  };

  deleteChatRecordByChatCategoryId: DeleteChatRecordByChatCategoryIdFun =
    async (chatCategoryId) => {
      try {
        const db = await this.IndexedDB.openDB();
        const result = await this.IndexedDB.cursorDelete<
          typeof chatCategoryId,
          Chat.ChatRecord
        >(db, 'chat', 'chatCategoryId', chatCategoryId);
        return result;
      } catch (error) {
        return Promise.reject(error);
      }
    };

  deleteChatRecordById: DeleteChatRecordByIdFun = async (id) => {
    try {
      const db = await this.IndexedDB.openDB();
      const result = await this.IndexedDB.deleteDB<typeof id>(db, 'chat', id);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
