import {
  RegisterFun,
  ForgetPasswordFun,
  LoginFun,
  SetUserInfoFun,
} from './../../services/API/global/interface';

export class Register {
  static readonly type = 'register';
  constructor(public params: FunType.FirstParameters<RegisterFun>) {}
}

export class ForgetPassword {
  static readonly type = 'forget password';
  constructor(public params: FunType.FirstParameters<ForgetPasswordFun>) {}
}

export class Login {
  static readonly type = 'login';
  constructor(public params: FunType.FirstParameters<LoginFun>) {}
}

export class Logout {
  static readonly type = 'logout';
  constructor() {}
}

export class GetUserTokenInfo {
  static readonly type = 'get user token info';
  constructor() {}
}

export class GetUserInfo {
  static readonly type = 'get user info';
  constructor() {}
}

export class SetUserInfo {
  static readonly type = 'set user info';
  constructor(public params: FunType.FirstParameters<SetUserInfoFun>) {}
}
