import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { TokenModel, TOKEN_KEY } from './model';
import { GetLastSpentToken, RechargeToken, GetRechargeList } from './action';

import { ApiTokenService } from '../../services/API/global';
import { StorageService } from '../../services/storage/storage.service';

@State<TokenModel>({
  name: TOKEN_KEY,
  defaults: {
    lastSpentToken: 0,
    rechargeList: [],
    rechargeCount: 0,
    rechaegeAmount: 0,
  },
})
@Injectable({
  providedIn: 'root',
})
export class TokenState {
  constructor(
    private apiTokenService: ApiTokenService,
    private storageService: StorageService
  ) {}

  ngxsOnInit(context: StateContext<TokenModel>): void {
    const user = this.storageService.getLocalStroage<User.User>(
      this.storageService.USER
    ) as User.User;

    if (user) {
      context.dispatch(new GetLastSpentToken());
    }
  }

  @Action(GetLastSpentToken)
  async getLastSpentToken(
    context: StateContext<TokenModel>,
    action: GetLastSpentToken
  ): Promise<void> {
    try {
      const state = context.getState();
      let lastSpentToken = state.lastSpentToken;
      if (action.lastSpentToken) {
        lastSpentToken = action.lastSpentToken;
      } else {
        const result = await this.apiTokenService.getLastSpendToken();
        const { data } = result;
        const { token } = data;
        lastSpentToken = token;
      }

      context.patchState({
        lastSpentToken,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(RechargeToken)
  async rechargeToken(
    context: StateContext<TokenModel>,
    action: RechargeToken
  ): Promise<void> {
    try {
      const result = await this.apiTokenService.recharge(action.params);
      const { data } = result;
      const { amount } = data;

      context.patchState({
        rechaegeAmount: amount,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetRechargeList)
  async getRechargeList(
    context: StateContext<TokenModel>,
    action: GetRechargeList
  ): Promise<void> {
    try {
      const result = await this.apiTokenService.getRechargeList(action.params);
      const { data } = result;
      const { totalCount, list } = data;

      const rechargeList = list.map((ele) => {
        const { name, remark, amount, rechargeTime } = ele;
        return { name, remark, amount, rechargeTime };
      });
      context.patchState({
        rechargeList,
        rechargeCount: totalCount,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
