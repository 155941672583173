import { InjectionToken } from '@angular/core';

export interface GlobalEnviroment {
  readonly production: boolean;
  readonly baseUrl: string;
  readonly token: string;
  readonly user: string;
  readonly captchaAppId: string;
}

export const GLOBAL_ENVIROMENT = new InjectionToken<GlobalEnviroment>(
  'global.env'
);
