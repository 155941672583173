import { SetUserInfoFun } from './../../services/API/global/interface';

export class GetPortraitList {
  static readonly type = 'get portrait list';
  constructor() {}
}

export class UpdatePortrait {
  static readonly type = 'update portrait';
  constructor(public params: FunType.FirstParameters<SetUserInfoFun>) {}
}
