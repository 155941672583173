
export enum IsLogin {
  /**
   * 登录
   */
  hasLogin = 2,
  /**
   * 未登录
   */
  notLogin = 1,
}
