import { State, Store, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ChatCategoryModel, CHAT_CATEGORY_KEY } from './model';
import {
  CreateChatCategory,
  GetChatCategoryList,
  DeleteChatCategoryById,
  UpdteChatCategory,
  ActiveChatCategoryById,
  InitChatCategory,
} from './action';
import { IndexedDBChatCategoryService } from '../../services/API/indexedDB';
import { v4 } from 'uuid';
import * as monent from 'moment';

@State<ChatCategoryModel>({
  name: CHAT_CATEGORY_KEY,
  defaults: {
    chatCategoryList: [],
    chatCategoryTotal: 0,
    activatedChatCategory: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class ChatCategoryState {
  constructor(
    private store: Store,
    private router: Router,
    private indexedDBChatCategoryService: IndexedDBChatCategoryService
  ) {}

  @Selector()
  static getActivatedChatCategory(state: ChatCategoryModel) {
    return state.activatedChatCategory;
  }

  @Action(InitChatCategory)
  initChatCategory(
    context: StateContext<ChatCategoryModel>,
    action: InitChatCategory
  ): void {
    this.store
      .dispatch(new GetChatCategoryList(action.userId))
      .subscribe(() => {
        const { chatCategoryList } = context.getState();
        if (!chatCategoryList.length) {
          this.store
            .dispatch(
              new CreateChatCategory({
                id: new Date().getTime(),
                name: 'New Chat',
                time: new Date().getTime(),
                memo: '',
                uuid: v4(),
                userId: action.userId,
              })
            )
            .subscribe(() => {
              const { chatCategoryList } = context.getState();
              const [chatCategory] = chatCategoryList;
              const { id } = chatCategory;
              this.router.navigate([], {
                queryParams: {
                  id,
                },
              });

              this.store.dispatch(new ActiveChatCategoryById(id));
            });
        } else {
          const activatedChatCategory =
            context.getState().activatedChatCategory;
          if (!activatedChatCategory) {
            let activeChatCategoryId = chatCategoryList[0].id;

            const chatCategory = chatCategoryList.find(
              (ele) =>
                ele.id ===
                Number(window.localStorage.getItem('activeChatCategory'))
            );
            if (chatCategory) {
              activeChatCategoryId = chatCategory.id;
            }
            this.router.navigate([], {
              queryParams: {
                id: activeChatCategoryId,
              },
            });
            this.store.dispatch(
              new ActiveChatCategoryById(activeChatCategoryId)
            );
          }
        }
      });
  }

  @Action(CreateChatCategory)
  async createChatCategory(
    context: StateContext<ChatCategoryModel>,
    action: CreateChatCategory
  ): Promise<void> {
    try {
      const result = await this.indexedDBChatCategoryService.createChatCategory(
        action.params
      );
      const { id, name, time, memo, uuid, userId } = result;

      const chatCategory: ChatCategory.CreateChatCategory = {
        id,
        name,
        time,
        memo,
        uuid,
        userId,
      };

      const state = context.getState();
      context.patchState({
        chatCategoryList: [chatCategory, ...state.chatCategoryList],
        chatCategoryTotal: state.chatCategoryTotal + 1,
      });
    } catch (error: AsAny.AsAny) {
      console.log(error);
      throw new Error(error.message);
    }
  }

  @Action(GetChatCategoryList)
  async getChatCategoryList(
    context: StateContext<ChatCategoryModel>,
    action: GetChatCategoryList
  ): Promise<void> {
    try {
      const result =
        await this.indexedDBChatCategoryService.getChatCategoryList(
          action.userId
        );
      const chatCategoryList = result
        .map((ele) => {
          const { name, id, time, memo, uuid, userId } = ele;
          return {
            name,
            id,
            time,
            memo,
            uuid,
            userId,
          };
        })
        .sort((a, b) => {
          return b.time - a.time;
        });

      context.patchState({
        chatCategoryList,
        chatCategoryTotal: chatCategoryList.length,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(DeleteChatCategoryById)
  async deleteChatCategoryById(
    context: StateContext<ChatCategoryModel>,
    action: DeleteChatCategoryById
  ): Promise<void> {
    try {
      await this.indexedDBChatCategoryService.deleteChatCategory(action.id);

      const state = context.getState();
      const chatCategoryList = state.chatCategoryList.filter(
        (ele) => ele.id !== action.id
      );
      context.patchState({
        chatCategoryList,
        chatCategoryTotal: chatCategoryList.length,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(UpdteChatCategory)
  async updteChatCategory(
    context: StateContext<ChatCategoryModel>,
    action: UpdteChatCategory
  ) {
    try {
      const chatCategory =
        await this.indexedDBChatCategoryService.getChatCategoryById(
          action.params.id
        );

      const result = await this.indexedDBChatCategoryService.updateChatCategory(
        Object.assign(chatCategory, action.params)
      );

      const state = context.getState();
      const chatCategoryList = state.chatCategoryList;
      for (const ele of chatCategoryList) {
        if (ele.id === action.params.id) {
          ele.memo = result.memo;
          ele.uuid = result.uuid;
          break;
        }
      }
      context.patchState({
        chatCategoryList: [...chatCategoryList],
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(ActiveChatCategoryById)
  activeChatCategoryById(
    context: StateContext<ChatCategoryModel>,
    action: ActiveChatCategoryById
  ): void {
    const state = context.getState();
    const chatCategoryList = state.chatCategoryList;

    const activatedChatCategory = chatCategoryList.find(
      (ele) => ele.id === action.id
    );

    window.localStorage.setItem(
      'activeChatCategory',
      activatedChatCategory?.id.toString() ?? ''
    );
    context.patchState({
      activatedChatCategory,
    });
  }
}
