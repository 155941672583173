import { Injectable } from '@angular/core';

import { IndexedDBService } from '../../../indexedDB/indexedDB.service';

import {
  UpdateChatModelFun,
  DeleteChatModelFun,
  GetChatModelFun,
} from './interface';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBChatModelService {
  constructor(private IndexedDB: IndexedDBService) {}

  deleteChatModel: DeleteChatModelFun = async (userId) => {
    try {
      const db = await this.IndexedDB.openDB();
      const result = await this.IndexedDB.cursorDelete<
        typeof userId,
        ChatModel.IndexedDB_ChatModel
      >(db, 'chatModel', 'userId', userId);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateChatModel: UpdateChatModelFun = async (params) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.updateDB<ChatModel.IndexedDB_ChatModel>(
        db,
        'chatModel',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getChatModel: GetChatModelFun = async (userId) => {
    try {
      const db = await this.IndexedDB.openDB();

      const data = await this.IndexedDB.cursorGetDataByIndex<
        typeof userId,
        ChatModel.IndexedDB_ChatModel
      >(db, 'chatModel', 'userId', userId);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
