import { Injectable, Inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  GLOBAL_ENVIROMENT,
  GlobalEnviroment,
} from '../../../../../environments/interface';
import { API } from '../../../../../config/api';

import { CustomHttpClientService } from '../../../http-client/http-client-service';
import { HttpResultService,StateCode } from '../../../http-result/http-result.service';

import {
  RegisterFun,
  LoginFun,
  SendSMSFun,
  ForgetPasswordFun,
  GetUserInfoFun,
  SetUserInfoFun,
} from './interface';

@Injectable({
  providedIn: 'root',
})
export class ApiUserService {
  private baseUrl = '';
  constructor(
    private customHttpClientService: CustomHttpClientService,
    @Inject(GLOBAL_ENVIROMENT) globalEnv: GlobalEnviroment,
    private httpResultService: HttpResultService
  ) {
    this.baseUrl = globalEnv.baseUrl;
  }

  register: RegisterFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<RegisterFun>>>(
          this.baseUrl + API.user.register,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  login: LoginFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<LoginFun>>>(this.baseUrl + API.user.login, params)
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  sendSMSForRegister: SendSMSFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<SendSMSFun>>>(
          this.baseUrl + API.user.sendSMSForRegister,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  sendSMSForModifyPassword: SendSMSFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<SendSMSFun>>>(
          this.baseUrl + API.user.sendSMSForModifyPassword,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  forgetPassword: ForgetPasswordFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<ForgetPasswordFun>>>(
          this.baseUrl + API.user.modifyPassword,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  getUserInfo: GetUserInfoFun = () =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetUserInfoFun>>>(
          this.baseUrl + API.user.infos,
          {}
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });

  setUserInfo: SetUserInfoFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<SetUserInfoFun>>>(
          this.baseUrl + API.user.setInfos,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            this.httpResultService.error(r);
            reject(r);
          }
        });
    });
}
