import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { Logout } from '../../ngxs/index.action';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';

export enum StateCode {
  success = 0, //成功
  error = -1, //失败
  paramError = -2, //参数错误
  unknowError = -3, //未知错误
  tokenInvalid = 100, //token无效

  streaming = 1, //发送中
  streamError = -4, //流发送失败
}

@Injectable({
  providedIn: 'root',
})
export class HttpResultService {
  constructor(
    private message: NzMessageService,
    private store: Store,
    private router: Router
  ) {}

  error<T>(e: Global.IResponse<T>, showMessage = true): void {
    if (e.code === StateCode.tokenInvalid) {
      this.store.dispatch(new Logout());
      this.router.navigate(['/login']);
    }

    if (showMessage) {
      if (e.message) this.message.error(e.message);
    }
  }
}
