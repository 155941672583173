import { Injectable, Inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CustomHttpClientService } from '../../../http-client/http-client-service';
import {
  GLOBAL_ENVIROMENT,
  GlobalEnviroment,
} from '../../../../../environments/interface';
import { API } from '../../../../../config/api';

import { StateCode } from '../../../http-result/http-result.service';
import { GetChatModelListFun, GetChatModelInfoFun } from './interface';

@Injectable({
  providedIn: 'root',
})
export class ApiChatModelService {
  private baseUrl = '';
  constructor(
    @Inject(GLOBAL_ENVIROMENT) globalEnv: GlobalEnviroment,
    private customHttpClientService: CustomHttpClientService
  ) {
    this.baseUrl = globalEnv.baseUrl;
  }

  getChatModelList: GetChatModelListFun = () =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetChatModelListFun>>>(
          this.baseUrl + API.chat.chatModelList,
          {}
        )
        .pipe(
          catchError((e) => {
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });

  getChatModelInfo: GetChatModelInfoFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetChatModelInfoFun>>>(
          this.baseUrl + API.chat.chatModelInfo,
          params
        )
        .pipe(
          catchError((e) => {
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
}
