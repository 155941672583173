import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { EnterpriseModel, ENTERPRISE_KEY } from './model';
import { GetEnterpriseInfo } from './action';

import { ApiEnterpriseService } from '../../services/API/global';

@State<EnterpriseModel>({
  name: ENTERPRISE_KEY,
  defaults: {
    enterprise: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class EnterpriseState {
  constructor(private apiEnterpriseService: ApiEnterpriseService) {}

  @Selector()
  static getEnterprise(state: EnterpriseModel) {
    return state.enterprise;
  }

  @Action(GetEnterpriseInfo)
  async getEnterpriseInfo(
    context: StateContext<EnterpriseModel>
  ): Promise<void> {
    try {
      const result = await this.apiEnterpriseService.infos();
      const { data } = result;
      const {
        websiteTitle,
        slogan,
        loginSlogan,
        themeType,
        chatLogo,
        wechat,
        wechatQrcode,
        loginLogo,
        aiName,
        favicon,
      } = data;
      const enterprise = {
        websiteTitle,
        slogan,
        loginSlogan,
        themeType,
        chatLogo,
        wechat,
        wechatQrcode,
        loginLogo,
        aiName,
        favicon,
      };
      context.patchState({
        enterprise,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
