import { FeedbackFun } from './../../services/API/global/interface';

/**
 * 聊天列表
 */
export class GetChatRecordList {
  static readonly type = '[chat] get chat record list';
  constructor(public chatCategoryId: number) {}
}

/**
 * 根据当前模型判断是否需要清除上下文
 */
export class IsClearContextByChatModel {
  static readonly type = '[chat] is clear clear context';
  constructor() {}
}

/**
 * 添加问题
 */
export class AddQuestion {
  static readonly type = '[chat] add question';
  constructor(public params: Chat.Quesition) {}
}

/**
 * 添加答案
 */
export class AddAnswer {
  static readonly type = '[chat] add answer';
  constructor(public params: Chat.Answer) {}
}

export class SetLastRequestId {
  static readonly type = '[chat] set last request id';
  constructor() {}
}

/**
 * 更新答案
 */
export class UpdateAnswer {
  static readonly type = '[chat] update answer';
  constructor(public params: Chat.Answer) {}
}

/**
 * 清空聊天
 */
export class DeleteChatRecordByChatCategoryId {
  static readonly type = '[chat] delete chat record by chat category id';
  constructor(public chatCategoryId: number) {}
}

/**
 * 删除聊天内容
 */
export class DeleteChatRecordById {
  static readonly type = 'delete chat record by id';
  constructor(public id: number) {}
}

/**
 * 重置checkbox状态
 */
export class ResetChatRecordCheckbox {
  static readonly type = '[chat] reset chat record checkbox';
  constructor() {}
}

/**
 * 更新聊天状态
 */
export class UpdateChatStatus {
  static readonly type = '[chat] update chat status';
  constructor(public params: Chat.ChatStatus) {}
}

/**
 * 编辑问题并重新回答
 */
export class EditAndReAnswer {
  static readonly type = '[chat] edit and re answer';
  constructor(public questionId: number, public questionText: string) {}
}

/**
 * 重新回答
 */
export class ReAnswer {
  static readonly type = '[chat] re answer';
  constructor(public answerId: number) {}
}

/**
 * 设置上一个回答的RequestId
 */
export class SetPreviousRequestId {
  static readonly type = '[chat]  set previous request id';
  constructor(public questionId: number) {}
}

export class Feedback {
  static readonly type = '[chat]  feedback';
  constructor(public params: ArrayType.First<Parameters<FeedbackFun>>) {}
}
