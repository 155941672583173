export class InitChatCategory {
  static readonly type = 'init chat category';
  constructor(public userId:number) {}
}

export class CreateChatCategory {
  static readonly type = 'create chat category';
  constructor(public params: ChatCategory.CreateChatCategory) {}
}

export class GetChatCategoryList {
  static readonly type = 'get chat category List';
  constructor(public userId: number | null | undefined) {}
}

export class DeleteChatCategoryById {
  static readonly type = 'delete chat category';
  constructor(public id: number) {}
}

export class UpdteChatCategory {
  static readonly type = 'update chat category';
  constructor(public params: ChatCategory.UpdateChatCategory) {}
}

/**
 * 激活聊天分类
 */
export class ActiveChatCategoryById {
  static readonly type = 'active chat category';
  constructor(public id: number) {}
}
