export type ChatCategoryIndex = Record<
  keyof ChatCategory.ChatCategory,
  boolean
>;
export type ChatRecordIndex = Record<keyof Chat.ChatRecord, boolean>;
export type ChatModelIndex = Record<
  keyof ChatModel.IndexedDB_ChatModel,
  boolean
>;

export type StoreNameType = keyof typeof DBInfo.storeName;

export type StoreIndex = ChatCategoryIndex | ChatRecordIndex | ChatModelIndex;

type Store<Index, U> = {
  [key in keyof U]: {
    id: 'id'; //主键
    index: Index; //索引
  };
};

export const DBInfo = {
  PROJECT_NAME: 'TradingSchool', //库名
  PROJECT_VERSION: 1, //版本
  storeName: {
    chatCategory: 'chatCategory', //聊天分类
    chat: 'chat', //聊天
    chatModel: 'chatModel', //会话模式
  },
};

const ChatCategoryStore: Store<
  ChatCategoryIndex,
  { chatCategory: 'chatCategory' }
> = {
  chatCategory: {
    id: 'id',
    index: {
      id: true,
      name: false,
      time: false,
      memo: false,
      uuid: false,
      userId: false,
    },
  },
};

const ChatRecordStore: Store<ChatRecordIndex, { chat: 'chat' }> = {
  chat: {
    id: 'id',
    index: {
      id: true,
      chatCategoryId: false,
      type: false,
      text: false,
      status: false,
      time: false,
      selected: false,
      memo: false,
      requestId: false,
      questionId: false,
      chatModel: false,
      uuid: false,
      isClearContext: false,
      score: false,
    },
  },
};

const ChatModelStore: Store<ChatModelIndex, { chatModel: 'chatModel' }> = {
  chatModel: {
    id: 'id',
    index: {
      id: true,
      name: false,
      value: false,
      type: false,
      remark: false,
      userId: false,
      sessionMode: false,
      leadingTitle: false,
      leadingQuestions: false,
    },
  },
};

export const DBStore: object = Object.assign(
  ChatCategoryStore,
  ChatRecordStore,
  ChatModelStore
);
