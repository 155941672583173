import { Injectable } from '@angular/core';
import { DBStore, DBInfo, StoreIndex, StoreNameType } from './store';

import { isValidKey } from '../../utils/is-validkey';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  constructor() {}

  openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const inexedDB = window.indexedDB;
      const request: IDBOpenDBRequest = inexedDB.open(
        DBInfo.PROJECT_NAME,
        DBInfo.PROJECT_VERSION
      );
      let db: IDBDatabase;
      request.onupgradeneeded = (event: AsAny.AsAny) => {
        db = event.target.result;
        for (const key in DBStore) {
          if (isValidKey(key, DBStore)) {
            const store: AsAny.AsAny = DBStore[key];
            let objectStore;
            //版本升级
            if (event.oldVersion > 0 && event.newVersion !== event.oldVersion) {
              objectStore = event.target.transaction.objectStore(key);
            }
            //初始化
            else {
              objectStore = db.createObjectStore(key, {
                keyPath: store.id,
              });
            }
            this.createStore(objectStore, store.index);
          }
        }
      };

      request.onsuccess = (event: AsAny.AsAny) => {
        db = event.target.result;
        resolve(db);
      };

      request.onerror = (event: AsAny.AsAny) => {
        db = event.target.result;
        reject(db);
      };
    });
  }

  /**
   * 创建索引
   *
   * @param objectStore  store
   * @param storeIndex  索引
   */
  private createStore(objectStore: IDBObjectStore, storeIndex: StoreIndex) {
    for (const key in storeIndex) {
      if (Object.prototype.hasOwnProperty.call(storeIndex, key)) {
        if (isValidKey(key, storeIndex)) {
          const unique = storeIndex[key];
          if (!objectStore.indexNames.contains(key)) {
            objectStore.createIndex(key, key, { unique: unique });
          }
        }
      }
    }
  }

  /**
   * 新增数据
   *
   * @param db
   * @param storeName
   * @param data
   * @returns
   */
  addData<T>(db: IDBDatabase, storeName: StoreNameType, data: T): Promise<T> {
    return new Promise((resolve, reject) => {
      const request: IDBRequest<IDBValidKey> = db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .add(data);

      request.onsuccess = () => {
        resolve(data);
      };

      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 更新数据
   *
   * @param db
   * @param storeName
   * @param data
   * @returns
   */
  updateDB<T>(db: IDBDatabase, storeName: StoreNameType, data: T): Promise<T> {
    return new Promise((resolve, reject) => {
      const request = db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .put(data);

      request.onsuccess = function () {
        resolve(data);
      };

      request.onerror = function () {
        reject();
      };
    });
  }

  /**
   * 通过主键查询单条数据
   *
   * @param db
   * @param storeName
   * @param key
   * @returns
   */
  getDataByKey<T>(
    db: IDBDatabase,
    storeName: StoreNameType,
    key: number
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const request: IDBRequest<AsAny.AsAny> = db
        .transaction([storeName])
        .objectStore(storeName)
        .get(key);

      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过游标获取批量数据
   *
   * @param db
   * @param storeName
   * @returns
   */
  cursorGetData<T>(db: IDBDatabase, storeName: StoreNameType): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const list: T[] = [];
      const store = db
        .transaction(storeName, 'readonly')
        .objectStore(storeName);
      const request = store.openCursor();

      request.onsuccess = (event: AsAny.AsAny) => {
        const cursor = event.target.result;
        if (cursor) {
          list.push(cursor.value);
          cursor.continue();
        } else {
          resolve(list);
        }
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过索引和游标查询批量数据
   *
   * @param db
   * @param storeName
   * @param indexName
   * @param indexValue
   * @returns
   */
  cursorGetDataByIndex<IndexValueType, Data>(
    db: IDBDatabase,
    storeName: StoreNameType,
    indexName: string,
    indexValue: IndexValueType,
    direction: IDBCursorDirection = 'next'
  ): Promise<Data[]> {
    return new Promise((resolve, reject) => {
      const list: Data[] = [];
      const store = db.transaction(storeName).objectStore(storeName);
      const request = store
        .index(indexName)
        .openCursor(IDBKeyRange.only(indexValue), direction);

      request.onsuccess = (event: AsAny.AsAny) => {
        const cursor = event.target.result;
        if (cursor) {
          list.push(cursor.value);
          cursor.continue();
        } else {
          resolve(list);
        }
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过主键删除数据
   *
   * @param db
   * @param storeName
   * @param id
   * @returns
   */
  deleteDB<T extends IDBValidKey>(
    db: IDBDatabase,
    storeName: StoreNameType,
    id: T
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .delete(id);

      request.onsuccess = () => {
        resolve();
      };

      request.onerror = () => {
        reject;
      };
    });
  }

  /**
   * 通过索引和游标删除数据
   *
   * @param db
   * @param storeName
   * @param indexName
   * @param indexValue
   * @returns
   */
  cursorDelete<IndexValueType, Data>(
    db: IDBDatabase,
    storeName: StoreNameType,
    indexName: string,
    indexValue: IndexValueType
  ): Promise<Data[]> {
    return new Promise((resolve, reject) => {
      const list: Data[] = [];

      const store = db
        .transaction(storeName, 'readwrite')
        .objectStore(storeName);
      const request = store
        .index(indexName)
        .openCursor(IDBKeyRange.only(indexValue));
      request.onsuccess = (event: AsAny.AsAny) => {
        const cursor = event.target.result;
        let deleteRequest;
        if (cursor) {
          deleteRequest = cursor.delete();
          deleteRequest.onsuccess = () => {
            list.push(cursor.value);
          };
          deleteRequest.onerror = () => {
            reject();
          };
          cursor.continue();
        } else {
          resolve(list);
        }
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过索引和游标查询总数
   *
   * @param db
   * @param storeName
   * @param indexName
   * @param indexValue
   * @returns
   */
  cursorGetDataCountByIndex<IndexValueType>(
    db: IDBDatabase,
    storeName: StoreNameType,
    indexName: string,
    indexValue: IndexValueType
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      const store = db
        .transaction(storeName, 'readonly')
        .objectStore(storeName);
      const request = store
        .index(indexName)
        .count(IDBKeyRange.only(indexValue));

      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        reject(0);
      };
    });
  }

  /**
   *
   * 通过索引和游标分页查询记录
   *
   * @param db
   * @param storeName
   * @param indexName
   * @param indexValue
   * @param advanced
   * @param page
   * @param pageSize
   * @returns
   */
  cursorGetDataByIndexAndPage<IndexValueType, Data>(
    db: IDBDatabase,
    storeName: StoreNameType,
    indexName: string,
    indexValue: IndexValueType,
    page = 1,
    pageSize = 10
  ): Promise<Data[]> {
    return new Promise((resolve, reject) => {
      const list: Data[] = [];
      let counter = 0;
      let advanced = true;
      const store = db
        .transaction(storeName, 'readwrite')
        .objectStore(storeName);
      const request = store
        .index(indexName)
        .openCursor(IDBKeyRange.only(indexValue), 'prev');

      request.onsuccess = (event: AsAny.AsAny) => {
        let cursor = event.target.result;
        if (page > 1 && advanced) {
          advanced = false;
          cursor.advance((page - 1) * pageSize);
          return;
        }

        if (cursor) {
          list.push(cursor.value);
          counter++;
          if (counter < pageSize) {
            cursor.continue();
          } else {
            resolve(list);
            cursor = null;
          }
        } else {
          resolve(list);
        }
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过游标分页查询记录
   *
   * @param db
   * @param storeName
   * @param page
   * @param pageSize
   * @returns
   */
  cursorGetDataByPage<Data>(
    db: IDBDatabase,
    storeName: StoreNameType,
    page = 1,
    pageSize = 10
  ): Promise<Data[]> {
    return new Promise((resolve, reject) => {
      const list: Data[] = [];
      let counter = 0;
      let advanced = true;
      const store = db
        .transaction(storeName, 'readwrite')
        .objectStore(storeName);
      const request = store.openCursor(null, 'prev');

      request.onsuccess = (event: AsAny.AsAny) => {
        let cursor = event.target.result;
        if (page > 1 && advanced) {
          advanced = false;
          cursor.advance((page - 1) * pageSize);
          return;
        }

        if (cursor) {
          list.push(cursor.value);
          counter++;
          if (counter < pageSize) {
            cursor.continue();
          } else {
            resolve(list);
            cursor = null;
          }
        } else {
          resolve(list);
        }
      };
      request.onerror = () => {
        reject();
      };
    });
  }

  /**
   * 通过游标查询总数
   *
   * @param db
   * @param storeName
   * @param indexName
   * @param indexValue
   * @returns
   */
  cursorGetDataCount(
    db: IDBDatabase,
    storeName: StoreNameType
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      const store = db
        .transaction(storeName, 'readonly')
        .objectStore(storeName);
      const request = store.count();

      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        reject(0);
      };
    });
  }
}
