export interface ChatModel {
  chatRecordList: Array<Chat.Answer | Chat.Quesition>; //聊天记录

  status: Chat.ChatStatus; //聊天状态
  isClearContext: Chat.IsClearContext; //是否清除上下文(模型)
  previousRequestId: string; //上一个回答的RequestId
  lastRequestId: string; //最新回答的RequestId,连接上下文使用
}

export const CHAT_KEY = 'chat';
