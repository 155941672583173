import { Injectable, Inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  GLOBAL_ENVIROMENT,
  GlobalEnviroment,
} from '../../../../../environments/interface';
import { API } from '../../../../../config/api';

import { CustomHttpClientService } from '../../../http-client/http-client-service';
import {
  HttpResultService,
  StateCode,
} from '../../../http-result/http-result.service';
import { StorageService } from '../../../storage/storage.service';

import {
  fetchEventSource,
  EventStreamContentType,
  EventSourceMessage,
} from '@chat1/fetch-event-source';
import {
  SendMessageFun,
  FeedbackFun,
  SendMessageSuccessResult,
} from './interface';
class RetriableError extends Error {}
class FatalError extends Error {}

interface StreamResponse<T = any> {
  data: T;
  code: number;
  msg: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiChatService {
  private baseUrl = '';
  constructor(
    @Inject(GLOBAL_ENVIROMENT) globalEnv: GlobalEnviroment,
    private httpResultService: HttpResultService,
    private storageService: StorageService,
    private customHttpClientService: CustomHttpClientService
  ) {
    this.baseUrl = globalEnv.baseUrl;
  }

  sendMessage: SendMessageFun = async (params, start, open, success, error) => {
    const { text, chatModel, chatType, requestId } = params;
    const body = {
      message: text,
      parentId: requestId,
      chatModel,
      chatType,
    };

    const ctrl = new AbortController();
    let result: SendMessageSuccessResult = {
      data: '',
      status: 'start',
      requestId: '',
      tokenSpent: 0,
      ctrl,
    };

    const token = this.storageService.getLocalStroage<string>(
      this.storageService.TOKEN
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    start(ctrl);
    await fetchEventSource(this.baseUrl + API.chat.sendMessage, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
      signal: ctrl.signal,
      openWhenHidden: true,
      async onopen(response: Response) {
        open(Object.assign(response, { ctrl }));
        if (
          response.ok &&
          response.headers.get('content-type') === EventStreamContentType
        ) {
          return;
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          throw new FatalError();
        } else {
          throw new RetriableError();
        }
      },
      onmessage: (ev: EventSourceMessage) => {
        try {
          const { data, event } = ev;
          switch (event) {
            //开始推流
            case 'start':
              result = {
                data: '',
                status: 'doing',
                requestId: '',
                tokenSpent: 0,
                ctrl,
              };
              success(result);
              break;

            //推流中
            case 'streaming':
              try {
                const response: StreamResponse = JSON.parse(data);
                if (response.code === StateCode.streaming) {
                  result = {
                    data: response.data ?? '',
                    status: 'doing',
                    requestId: '',
                    tokenSpent: 0,
                    ctrl,
                  };
                  success(result);
                }
              } catch (error) {
                ctrl.abort();
              }
              break;

            //推流结束
            case 'close':
              try {
                const response: StreamResponse = JSON.parse(data);
                switch (response.code) {
                  //成功
                  case StateCode.success:
                    result = {
                      data: '',
                      status: 'finished',
                      requestId: response.data.requestId,
                      tokenSpent: response.data.tokenSpent,
                      ctrl,
                    };
                    success(result);
                    break;
                  //登录过期
                  case StateCode.tokenInvalid:
                    this.httpResultService.error({
                      code: StateCode.tokenInvalid,
                      data: '',
                      message: '登录失效,请重新登录',
                    });

                    result = {
                      data: '登录失效,请重新登录',
                      status: 'finished',
                      requestId: '',
                      tokenSpent: 0,
                      ctrl,
                    };
                    success(result);
                    break;
                  //无token或用户被禁用
                  case StateCode.error:
                    result = {
                      data: response.msg,
                      status: 'finished',
                      requestId: '',
                      tokenSpent: 0,
                      ctrl,
                    };
                    success(result);
                    break;
                  //推流中断
                  case StateCode.streamError:
                    result = {
                      data: '&#9209;',
                      status: 'finished',
                      tokenSpent: response.data.tokenSpent,
                      requestId: response.data.requestId,
                      ctrl,
                    };
                    success(result);
                    break;

                  default:
                    success({
                      data: '服务器繁忙，请稍后重试',
                      requestId: '',
                      status: 'finished',
                      ctrl,
                      tokenSpent: 0,
                    });
                    break;
                }
              } catch (error) {
                success({
                  data: '未知错误',
                  requestId: '',
                  status: 'finished',
                  ctrl,
                  tokenSpent: 0,
                });
                ctrl.abort();
              }
              break;
            //结束
            case 'end':
              ctrl.abort();
              break;
          }
        } catch (error) {
          success({
            data: '未知错误',
            requestId: '',
            status: 'finished',
            ctrl,
            tokenSpent: 0,
          });
          ctrl.abort();
        }
      },
      onerror: (err: any) => {
        console.log(err);
        ctrl.abort();
        error(err);
        if (err instanceof FatalError) {
          throw err; // rethrow to stop the operation
        }
      },
      onclose: () => {
        throw new RetriableError();
      },
    });
  };

  feedback: FeedbackFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<FeedbackFun>>>(
          this.baseUrl + API.chat.feedback,
          params
        )
        .pipe(
          catchError((e) => {
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
          }
        });
    });
}
