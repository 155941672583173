import { GetChatModelInfoFun } from './../../services/API/global/interface';

export class ChangeChatModel {
  static readonly type = '[chatModel] change chat model';
  constructor(public params: ChatModel.ChatModel, public userId: number) {}
}

export class GetChatModelList {
  static readonly type = '[chatModel] get chat model list';
  constructor() {}
}

export class SetDefaultChatModel {
  static readonly type = '[chatModel] set default chat model ';
  constructor(public userId: number) {}
}

export class GetChatModelInfo {
  static readonly type = '[chatModel] get chat model info ';
  constructor(public params: ArrayType.First<Parameters<GetChatModelInfoFun>>) {}
}
