import { Injectable, Inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  GLOBAL_ENVIROMENT,
  GlobalEnviroment,
} from '../../../../../environments/interface';
import { API } from '../../../../../config/api';

import { CustomHttpClientService } from '../../../http-client/http-client-service';
import { HttpResultService,StateCode } from '../../../http-result/http-result.service';
import {
  GetUserTokenInfoFun,
  GetLastSpendTokenFun,
  GetRechargeListFun,
  RechargeFun,
} from './interface';

@Injectable({
  providedIn: 'root',
})
export class ApiTokenService {
  private baseUrl = '';
  constructor(
    private customHttpClientService: CustomHttpClientService,
    @Inject(GLOBAL_ENVIROMENT) globalEnv: GlobalEnviroment,
    private httpResultService: HttpResultService
  ) {
    this.baseUrl = globalEnv.baseUrl;
  }

  getLastSpendToken: GetLastSpendTokenFun = () =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetLastSpendTokenFun>>>(
          this.baseUrl + API.token.lastSpent,
          {}
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
            this.httpResultService.error(r, false);
          }
        });
    });

  getRechargeList: GetRechargeListFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetRechargeListFun>>>(
          this.baseUrl + API.token.rechargeList,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
            this.httpResultService.error(r);
          }
        });
    });

  recharge: RechargeFun = (params) =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<RechargeFun>>>(
          this.baseUrl + API.token.recharge,
          params
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
            this.httpResultService.error(r);
          }
        });
    });

  getUserTokenInfo: GetUserTokenInfoFun = () =>
    new Promise((resolve, reject) => {
      this.customHttpClientService
        .post<Awaited<ReturnType<GetUserTokenInfoFun>>>(
          this.baseUrl + API.token.infos,
          {}
        )
        .pipe(
          catchError((e) => {
            this.httpResultService.error(e);
            reject(e);
            return throwError(() => new Error());
          })
        )
        .subscribe((r) => {
          const { code } = r;
          if (code === StateCode.success) {
            resolve(r);
          } else {
            reject(r);
            this.httpResultService.error(r, false);
          }
        });
    });
}
