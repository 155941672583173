import { Injectable } from '@angular/core';
import { IndexedDBService } from '../../../indexedDB/indexedDB.service';

import {
  CreateChatCategoryFun,
  GetChatCategoryListFun,
  DeleteChatCategoryFun,
  UpdateChatCategoryFun,
  GetChatCategoryByIdFun,
} from './interface';

@Injectable({
  providedIn: 'root',
})
export class  IndexedDBChatCategoryService {
  constructor(private IndexedDB: IndexedDBService) {}

  createChatCategory: CreateChatCategoryFun = async (params) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data =
        await this.IndexedDB.addData<ChatCategory.CreateChatCategory>(
          db,
          'chatCategory',
          params
        );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getChatCategoryList: GetChatCategoryListFun = async (userId) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.cursorGetDataByIndex<
        typeof userId,
        ChatCategory.ChatCategory
      >(db, 'chatCategory', 'userId', userId);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  getChatCategoryById: GetChatCategoryByIdFun = async (id) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.getDataByKey<ChatCategory.ChatCategory>(
        db,
        'chatCategory',
        id
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  deleteChatCategory: DeleteChatCategoryFun = async (chatCategoryId) => {
    try {
      const db = await this.IndexedDB.openDB();
      const result = await this.IndexedDB.deleteDB<typeof chatCategoryId>(
        db,
        'chatCategory',
        chatCategoryId
      );
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  updateChatCategory: UpdateChatCategoryFun = async (params) => {
    try {
      const db = await this.IndexedDB.openDB();
      const data = await this.IndexedDB.updateDB<ChatCategory.ChatCategory>(
        db,
        'chatCategory',
        params
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
