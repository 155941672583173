import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { PortraitModel, PORTRAIT_KEY } from './model';
import { GetPortraitList } from './action';

import { portraits } from './../../services/const-data';

@State<PortraitModel>({
  name: PORTRAIT_KEY,
  defaults: {
    portraitList: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class PortraitState {
  constructor() {}

  @Action(GetPortraitList)
  async getPortraitList(context: StateContext<PortraitModel>): Promise<void> {
    try {
      context.patchState({
        portraitList: portraits,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
