import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ChatModelModel, CHAT_MODEL_KEY } from './model';
import {
  GetChatModelList,
  SetDefaultChatModel,
  ChangeChatModel,
  GetChatModelInfo,
} from './action';

import { ApiChatModelService } from '../../services/API/global';
import { IndexedDBChatModelService } from './../../services/API/indexedDB';

@State<ChatModelModel>({
  name: CHAT_MODEL_KEY,
  defaults: {
    chatModel: null,
    chatModelList: [],

    leadingTitle: '',
    leadingQuestions: [],
  },
})
@Injectable({
  providedIn: 'root',
})
export class ChatModeState {
  constructor(
    private apiChatModelService: ApiChatModelService,
    private indexedDBChatModelService: IndexedDBChatModelService
  ) {}

  @Selector()
  static getActivatedChatModel(state: ChatModelModel) {
    return state.chatModel;
  }

  @Action(ChangeChatModel, {
    cancelUncompleted: true,
  })
  async changeChatModel(
    context: StateContext<ChatModelModel>,
    action: ChangeChatModel
  ): Promise<void> {
    try {
      await this.indexedDBChatModelService.deleteChatModel(action.userId);

      const params: ChatModel.IndexedDB_ChatModel = Object.assign(
        action.params,
        {
          id: new Date().getTime(),
          userId: action.userId,
        }
      );

      const result = await this.indexedDBChatModelService.updateChatModel(
        params
      );
      context.patchState({
        chatModel: result,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetChatModelList, {
    cancelUncompleted: true,
  })
  async getChatModelList(context: StateContext<ChatModelModel>): Promise<void> {
    try {
      const result = await this.apiChatModelService.getChatModelList();
      const { data } = result;

      const chatModelList = data.map((ele) => {
        const { name, value, type, remark, sessionMode } = ele;
        return {
          name,
          value,
          type,
          remark,
          sessionMode,
        };
      });
      context.patchState({
        chatModelList,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(SetDefaultChatModel, {
    cancelUncompleted: true,
  })
  async setDefaultChatModel(
    context: StateContext<ChatModelModel>,
    action: SetDefaultChatModel
  ): Promise<void> {
    try {
      const state = context.getState();
      const chatModelList = state.chatModelList;

      const result = await this.indexedDBChatModelService.getChatModel(
        action.userId
      );
      const [indexedDBChatModel] = result;
      let chatModel = null;
      if (indexedDBChatModel) {
        chatModel = chatModelList.find(
          (ele) =>
            `${ele.type}_${ele.value}` ===
            `${indexedDBChatModel.type}_${indexedDBChatModel.value}`
        );
      }
      context.patchState({
        chatModel:
          indexedDBChatModel && chatModel
            ? indexedDBChatModel
            : chatModelList[0],
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetChatModelInfo, {
    cancelUncompleted: true,
  })
  async getChatModelInfo(
    context: StateContext<ChatModelModel>,
    action: GetChatModelInfo
  ) {
    try {
      const { data } = await this.apiChatModelService.getChatModelInfo(
        action.params
      );
      const { leadingQuestions, leadingTitle } = data;
      context.patchState({
        leadingQuestions:
          leadingQuestions && leadingQuestions.length
            ? leadingQuestions
            : [`什么是ChatGPT?`, `北京都有哪些美食?`, `请推荐一些不错的电影?`],
        leadingTitle: leadingTitle
          ? leadingTitle
          : '欢迎使用,您可以通过点击下方示例,了解更多信息',
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
