import { Inject, Injectable } from '@angular/core';

import {
  GLOBAL_ENVIROMENT,
  GlobalEnviroment,
} from '../../../environments/interface';
import { isString } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  TOKEN: string;
  USER: string;

  constructor(@Inject(GLOBAL_ENVIROMENT) globalEnv: GlobalEnviroment) {
    this.TOKEN = globalEnv.token;
    this.USER = globalEnv.user;
  }

  setLocalStroage<T>(key: string, value: T) {
    if (isString(value) && typeof value === 'string') {
      window.localStorage.setItem(key, value);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getLocalStroage<T>(key: string): T | string {
    const localStorage = window.localStorage.getItem(key);

    if (localStorage) {
      try {
        return JSON.parse(localStorage);
      } catch (error) {
        return localStorage;
      }
    }
    return '';
  }

  removeAllLocalStorage(): void {
    window.localStorage.removeItem(this.TOKEN);
    window.localStorage.removeItem(this.USER);
  }
}
