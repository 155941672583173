import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
// import { StateResetAll } from 'ngxs-reset-plugin';

import { UserModel, USER_KEY } from './model';
import { Register, Logout, Login } from './action';
import {
  GetChatModelList,
  SetDefaultChatModel,
  GetUserTokenInfo,
  ForgetPassword,
  GetUserInfo,
  SetUserInfo,
  GetPortraitList,
  InitChatCategory,
  GetChatModelInfo,
} from '../index.action';
import { ChatModeState } from './../index.state';

import { ApiUserService, ApiTokenService } from '../../services/API/global';
import { StorageService } from '../../services/storage/storage.service';
import { portraits, IsLogin } from './../../services/const-data';

@State<UserModel>({
  name: USER_KEY,
  defaults: {
    user: {
      id: null,
      username: '',
      nickname: '',
      avatar: null,
      token: {
        totalRecharge: 0,
        rechargeTimes: 0,
        balance: 0,
      },
    },
    isLogin: IsLogin.notLogin,
  },
})
@Injectable({
  providedIn: 'root',
})
export class UserState {
  constructor(
    private apiUserService: ApiUserService,
    private storageService: StorageService,
    private apiTokenService: ApiTokenService,
    private store: Store
  ) {}

  ngxsOnInit(context: StateContext<UserModel>): void {
    this.storageService.setLocalStroage(
      this.storageService.TOKEN,
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE4OSwiaWF0IjoxNzE4MzUzODAzLCJleHAiOjE3MTg2MTMwMDN9.wTc0G4qJ9I0YL7SkkycWTDvzY_WgECW2c6vtGqxpt0s'
    );
    context
      .dispatch(new GetUserInfo())
      .pipe()
      .subscribe(() => {
        const { user } = context.getState();
        const { id } = user;
        if (id) {
          context
            .dispatch([
              new GetPortraitList(),
              new GetChatModelList(),
              new InitChatCategory(id),
              new GetUserTokenInfo(),
            ])
            .subscribe(() => {
              context.dispatch(new SetDefaultChatModel(id)).subscribe(() => {
                const activeChatModal = this.store.selectSnapshot(
                  ChatModeState.getActivatedChatModel
                );
                if (activeChatModal) {
                  const { type, value } = activeChatModal;
                  this.store.dispatch(
                    new GetChatModelInfo({
                      chatModel: value,
                      chatType: type,
                    })
                  );
                }
              });
              context.patchState({
                isLogin: IsLogin.hasLogin,
              });
            });
        }
      });
  }

  @Action(Register)
  async register(_: StateContext<UserModel>, action: Register): Promise<void> {
    try {
      await this.apiUserService.register(action.params);
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(Logout)
  logout(): void {
    try {
      // this.storageService.removeAllLocalStorage();
      // this.store.dispatch(new StateResetAll());
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(Login)
  async login(context: StateContext<UserModel>, action: Login): Promise<void> {
    try {
      const result = await this.apiUserService.login(action.params);

      const { data } = result;
      const { accessToken } = data;
      this.storageService.setLocalStroage(
        this.storageService.TOKEN,
        accessToken
      );
      context.patchState({
        isLogin: IsLogin.hasLogin,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetUserTokenInfo)
  async getUserTokenInfo(context: StateContext<UserModel>): Promise<void> {
    try {
      const result = await this.apiTokenService.getUserTokenInfo();
      const { data } = result;
      const { totalRecharge, rechargeTimes, balance } = data;
      const state = context.getState();

      const user = Object.assign({}, state.user, {
        token: { totalRecharge, rechargeTimes, balance },
      });

      this.storageService.setLocalStroage(this.storageService.USER, user);

      context.patchState({
        user: user,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(ForgetPassword)
  async ForgetPassword(
    context: StateContext<UserModel>,
    action: ForgetPassword
  ): Promise<void> {
    try {
      await this.apiUserService.forgetPassword(action.params);
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetUserInfo)
  async GetUserInfo(context: StateContext<UserModel>) {
    try {
      const result = await this.apiUserService.getUserInfo();
      const { data } = result;
      const { id, nickname, username, avatar } = data;

      const state = context.getState();

      const user = Object.assign({}, state.user, {
        id,
        username,
        nickname,
        avatar: Number(avatar) || portraits[0].id,
      });

      this.storageService.setLocalStroage(this.storageService.USER, user);

      context.patchState({
        user,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(SetUserInfo)
  async SetUserInfo(context: StateContext<UserModel>, action: SetUserInfo) {
    try {
      await this.apiUserService.setUserInfo(action.params);
      const state = context.getState();
      const user = Object.assign({}, state.user, action.params);

      this.storageService.setLocalStroage(this.storageService.USER, user);

      context.patchState({
        user,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
