import { State, Store, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ChatModel, CHAT_KEY } from './model';
import {
  GetChatRecordList,
  AddAnswer,
  AddQuestion,
  UpdateAnswer,
  DeleteChatRecordByChatCategoryId,
  DeleteChatRecordById,
  ResetChatRecordCheckbox,
  UpdateChatStatus,
  IsClearContextByChatModel,
  SetLastRequestId,
  EditAndReAnswer,
  SetPreviousRequestId,
  ReAnswer,
  Feedback,
} from './action';
import { ChatCategoryState, ChatModeState } from '../index.state';

import { IndexedDBChatService } from './../../services/API/indexedDB';
import { ApiChatService } from '../../services/API/global';
import { IsClearContext, SessionMode } from './../../services/const-data';
import { findLastIndex } from 'lodash';

@State<ChatModel>({
  name: CHAT_KEY,
  defaults: {
    chatRecordList: [],

    status: 'ready',
    isClearContext: IsClearContext.notClear,
    previousRequestId: '',
    lastRequestId: '',
  },
})
@Injectable({
  providedIn: 'root',
})
export class ChatRecordState {
  constructor(
    private indexedDBChatService: IndexedDBChatService,
    private store: Store,
    private apiChatService: ApiChatService
  ) {}

  @Action(IsClearContextByChatModel)
  isClearContextByChatModel(context: StateContext<ChatModel>) {
    const state = context.getState();
    const lastChatRecord =
      state.chatRecordList[state.chatRecordList.length - 1];
    const activeChatModal = this.store.selectSnapshot(
      ChatModeState.getActivatedChatModel
    );

    let isClearContext = IsClearContext.notClear;
    //最后记录的模型与当前激活的模型不一致时,清除上下文
    if (
      lastChatRecord &&
      `${lastChatRecord?.chatModel?.type}_${lastChatRecord?.chatModel?.value}` !==
        `${activeChatModal?.type}_${activeChatModal?.value}`
    ) {
      isClearContext = IsClearContext.clear;
    }

    //模型为问答模式时,清除上下文
    if (activeChatModal?.sessionMode === SessionMode.questionAndAnswer) {
      isClearContext = IsClearContext.clear;
    }

    context.patchState({
      isClearContext,
    });
  }

  @Action(AddQuestion)
  async addQuestion(
    context: StateContext<ChatModel>,
    action: AddQuestion
  ): Promise<void> {
    try {
      const result = await this.indexedDBChatService.addQuestion(action.params);
      const {
        id,
        chatCategoryId,
        type,
        text,
        time,
        memo,
        selected,
        status,
        uuid,
        chatModel,
        isClearContext,
      } = result;
      const chat: Chat.Quesition = {
        id,
        chatCategoryId,
        type,
        text,
        time,
        selected,
        memo,
        status,
        uuid,
        chatModel,
        isClearContext,
      };

      const state = context.getState();
      context.patchState({
        chatRecordList: [...state.chatRecordList, chat],
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error);
    }
  }

  @Action(AddAnswer)
  async addAnswer(
    context: StateContext<ChatModel>,
    action: AddAnswer
  ): Promise<void> {
    try {
      const result = await this.indexedDBChatService.addAnswer(action.params);
      const {
        id,
        chatCategoryId,
        type,
        text,
        status,
        time,
        selected,
        memo,
        requestId,
        questionId,
        chatModel,
        uuid,
        score,
      } = result;

      const chat: Chat.Answer = {
        id,
        chatCategoryId,
        type,
        text,
        status,
        time,
        selected,
        memo,
        requestId,
        questionId,
        chatModel,
        uuid,
        score,
      };

      const state = context.getState();
      context.patchState({
        chatRecordList: [...state.chatRecordList, chat],
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error);
    }
  }

  @Action(UpdateAnswer)
  async UpdateAnswer(
    context: StateContext<ChatModel>,
    action: UpdateAnswer
  ): Promise<void> {
    try {
      const result = await this.indexedDBChatService.updateAnswer(
        action.params
      );
      const { chatCategoryId } = action.params;
      const state = context.getState();
      let chatRecordList = state.chatRecordList;

      const activatedChatCategory = this.store.selectSnapshot(
        ChatCategoryState.getActivatedChatCategory
      );

      if (activatedChatCategory?.id === chatCategoryId) {
        chatRecordList = [...chatRecordList, result];
      }
      context.patchState({ chatRecordList });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(GetChatRecordList)
  async getChatRecord(
    context: StateContext<ChatModel>,
    action: GetChatRecordList
  ): Promise<void> {
    try {
      const result = await this.indexedDBChatService.getChatRecordList(
        action.chatCategoryId
      );

      const chatRecordList: Chat.ChatRecords = result.map(
        ({
          id,
          chatCategoryId,
          type,
          text,
          status,
          time,
          selected,
          memo,
          requestId,
          questionId,
          chatModel,
          uuid,
          score,
          isClearContext,
        }) => ({
          id,
          chatCategoryId,
          type,
          text,
          status,
          time,
          selected,
          memo,
          requestId,
          questionId,
          chatModel,
          uuid,
          score: score ?? 3,
          isClearContext,
        })
      );

      console.log(result)

      context.patchState({
        chatRecordList,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(ResetChatRecordCheckbox)
  resetChatRecord(context: StateContext<ChatModel>) {
    const state = context.getState();
    const chatRecordList = state.chatRecordList;
    chatRecordList.forEach((ele) => (ele.selected = false));
    //不使用immutable方式更新,防止派发数据时再次滚动会话页面
    context.patchState({
      chatRecordList,
    });
  }

  @Action(DeleteChatRecordByChatCategoryId)
  async deleteChatRecordByChatCategoryId(
    context: StateContext<ChatModel>,
    action: DeleteChatRecordByChatCategoryId
  ): Promise<void> {
    try {
      await this.indexedDBChatService.deleteChatRecordByChatCategoryId(
        action.chatCategoryId
      );

      const state = context.getState();
      const chatRecordList = state.chatRecordList.filter(
        (ele) => ele.chatCategoryId !== action.chatCategoryId
      );
      context.patchState({
        chatRecordList,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(DeleteChatRecordById)
  async deleteChatRecordById(
    context: StateContext<ChatModel>,
    action: DeleteChatRecordById
  ): Promise<void> {
    try {
      await this.indexedDBChatService.deleteChatRecordById(action.id);

      const state = context.getState();
      const chatRecordList = state.chatRecordList.filter(
        (ele) => ele.id !== action.id
      );

      context.patchState({
        chatRecordList,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(UpdateChatStatus)
  updateChatStatus(
    context: StateContext<ChatModel>,
    action: UpdateChatStatus
  ): void {
    try {
      context.patchState({
        status: action.params,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(ReAnswer, {
    cancelUncompleted: true,
  })
  async reAnswer(context: StateContext<ChatModel>, action: ReAnswer) {
    try {
      await this.indexedDBChatService.deleteChatRecordById(action.answerId);

      const state = context.getState();
      const chatRecordList = state.chatRecordList.filter(
        (ele) => ele.id !== action.answerId
      );

      context.patchState({
        chatRecordList,
      });
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(EditAndReAnswer, {
    cancelUncompleted: true,
  })
  async EditAndReAnswer(
    context: StateContext<ChatModel>,
    action: EditAndReAnswer
  ) {
    try {
      const state = context.getState();

      const _question = state.chatRecordList.find(
        (ele) => ele.type === 'question' && ele.id === action.questionId
      ) as Chat.Quesition;

      const _answer = state.chatRecordList.find(
        (ele) => ele.type === 'answer' && ele.questionId === action.questionId
      ) as Chat.Answer;

      if (_question && _answer) {
        const question: Chat.Quesition = Object.assign(_question, {
          text: action.questionText,
        });

        await Promise.all([
          this.indexedDBChatService.deleteChatRecordById(_answer.id),
          this.indexedDBChatService.updateQuestion(question),
        ]);

        const chatRecordList = state.chatRecordList.filter(
          (ele) => ele.id !== _answer.id
        );
        for (const ele of chatRecordList) {
          if (ele.id === action.questionId) {
            ele.text = action.questionText;
            break;
          }
        }

        context.patchState({
          chatRecordList,
        });
      }
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }

  @Action(SetPreviousRequestId, {
    cancelUncompleted: true,
  })
  setPreviousRequestId(
    context: StateContext<ChatModel>,
    action: SetPreviousRequestId
  ) {
    const state = context.getState();
    const chatRecordList = state.chatRecordList;

    const index = chatRecordList.findIndex(
      (ele) => ele.id === action.questionId && ele.type === 'question'
    );
    const question = chatRecordList[index] as Chat.Quesition;
    if (question) {
      if (question.isClearContext === IsClearContext.clear) {
        context.patchState({
          previousRequestId: '',
        });
      } else if (question.isClearContext === IsClearContext.notClear) {
        //由于对话列表头部问题内的isClearContext默认为未清除,所以需要额外处理
        if (index < 1) {
          context.patchState({
            previousRequestId: '',
          });
        } else {
          const answer = chatRecordList[index - 1] as Chat.Answer;
          if (answer.type === 'answer') {
            context.patchState({
              previousRequestId: answer.requestId,
            });
          }
        }
      }
    }
  }

  @Action(SetLastRequestId, {
    cancelUncompleted: true,
  })
  setLastRequestId(context: StateContext<ChatModel>) {
    const state = context.getState();
    const chatRecordList = state.chatRecordList;
    const isClearContext = state.isClearContext;

    /**
     * 当前模型与最新回答的模型不一致时,清除RequestId
     */
    if (isClearContext === IsClearContext.clear) {
      context.patchState({
        lastRequestId: '',
      });
    } else if (isClearContext === IsClearContext.notClear) {
      const index = findLastIndex(
        chatRecordList,
        (ele) => ele.type === 'answer'
      );
      const answer = chatRecordList[index] as Chat.Answer;
      if (answer) {
        context.patchState({
          lastRequestId: answer.requestId,
        });
      } else {
        context.patchState({
          lastRequestId: '',
        });
      }
    }
  }

  @Action(Feedback, {
    cancelUncompleted: true,
  })
  async feedback(context: StateContext<ChatModel>, action: Feedback) {
    try {
      const { requestId, score } = action.params;
      await this.apiChatService.feedback(action.params);

      const state = context.getState();
      const chatRecordList = state.chatRecordList;

      const answer = chatRecordList.find(
        (ele) => ele.type === 'answer' && ele.requestId === requestId
      ) as Chat.Answer;

      if (answer) {
        await this.indexedDBChatService.updateAnswer(
          Object.assign(answer, {
            score,
          })
        );

        for (const ele of chatRecordList) {
          if (ele.type === 'answer' && ele.requestId === requestId) {
            ele.score = score;
            break;
          }
        }
        context.patchState({
          chatRecordList,
        });
      }
    } catch (error: AsAny.AsAny) {
      throw new Error(error.message);
    }
  }
}
