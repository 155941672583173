/**
 * 是否清除上下文
 */
export enum IsClearContext {
  notClear = 1, //不清除
  clear = 2, //清除
}

/**
 * 反馈评分
 */
export enum FeedbackScore {
  dislike = 0,
  default = 3,
  like = 5,
}
