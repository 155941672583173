import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { isObject, isBoolean, isString, isNumber, isArray } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpClientService {
  constructor(private http: HttpClient) {}

  post<T>(baseurl: string, data: any): Observable<T> {
    return this.http.post<T>(baseurl, data, {
      observe: 'body',
      responseType: 'json',
    });
  }

  get<T>(baseurl: string, data: any): Observable<T> {
    return this.http.get<T>(baseurl, {
      observe: 'body',
      responseType: 'json',
      params: data,
    });
  }

  put<T>(baseurl: string, data: any): Observable<T> {
    return this.http.put<T>(baseurl, data, {
      observe: 'body',
      responseType: 'json',
    });
  }

  delete<T>(baseurl: string, data: any): Observable<T> {
    return this.http.delete<T>(baseurl, {
      observe: 'body',
      responseType: 'json',
      params: data,
    });
  }

  private checkParams(object: AsAny.AsAny): AsAny.AsAny {
    const DATA: AsAny.AsAny = {};
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const ele: string = object[key];
        if (isObject(ele)) {
          if (Object.keys(ele).length) {
            DATA[key] = ele;
          }
        } else if (
          (key === 'page' || key === 'status' || ele) &&
          isNumber(ele)
        ) {
          DATA[key] = ele;
        } else if ((isString(ele) || isBoolean(ele) || isArray(ele)) && ele) {
          DATA[key] = ele;
        }
      }
    }
    return DATA;
  }
}
