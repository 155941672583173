export const isArray = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Array]' ? true : false;

export const isNull = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Null]' ? true : false;

export const isObject = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Object]' ? true : false;

export const isNumber = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Number]' ? true : false;

export const isUndefined = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Undefined]' ? true : false;

export const isString = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object String]' ? true : false;

export const isBoolean = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Boolean]' ? true : false;

export const isFunction = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Function]' ? true : false;

export const isSymbol = (value: AsAny.AsAny): boolean =>
  Object.prototype.toString.call(value) === '[object Symbol]' ? true : false;
